import { Injectable } from '@angular/core';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { EncrDecrService } from './encr-decr-service.service';

@Injectable({
  providedIn: 'root'
})
export class SerService {
  httpOptions: { headers: HttpHeaders; };
  apiUrl: string;
  urls: string;
  token: string;
  urlL: any;
  path: any;

  constructor(private http: HttpClient, private EncrDecr: EncrDecrService) {
    this.token = localStorage.getItem('token');
    // this.apiUrl = "http://backendsonic.test/";
    this.apiUrl = "https://api.namnamapp.com/";
    this.path = "https://api.namnamapp.com/api/";

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }),
    };
  }

  getUsers(): Observable<any> {
    this.urls = this.apiUrl + "api/users";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createUsers(name, password, repass, tipo): Observable<any> {
    var datoaEnviar = {
      "name": name,
      "password": password,
      "password_confirmation": repass,
      "tipo": tipo,
    }
    this.urls = this.apiUrl + "api/register-user";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editUsers(id, email, password, repass, tipo): Observable<any> {
    var datoaEnviar = {
      "name": email,
      "password": password,
      "password_confirmation": repass,
      "tipo": tipo,
    }
    this.urls = this.apiUrl + "api/update-user/" + id;
    return this.http.put(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }
  updatePricePedido(idPedido,costo): Observable<any> {
    this.urls = this.apiUrl + "api/update-ped/"+idPedido+'';

    var datoaEnviar = {
      "costoenvio": costo
  }
    return this.http.put(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }
  deleteUsers(id): Observable<any> {
    this.urls = this.apiUrl + "api/delete-user/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editStaUser(id, verified): Observable<any> {
    var datoaEnviar = {
      "verified": verified
    }
    this.urls = this.apiUrl + "api/update-user/" + id;
    return this.http.put(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getClientes(): Observable<any> {
    this.urls = this.apiUrl + "api/clientes";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editCliente(id, nombres, correo, numero_cel, domicilio, password, password_confirmation): Observable<any> {
    var datoaEnviar = {
      "nombres": nombres,
      "correo": correo,
      "numero_cel": numero_cel,
      "domicilio": domicilio,
      "password": password,
      "password_confirmation": password_confirmation
    }
    this.urls = this.apiUrl + "api/update-clie/" + id;
    return this.http.put(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  statusCliente(id, estado): Observable<any> {
    var datoaEnviar = {
      "verified": estado,
    }
    this.urls = this.apiUrl + "api/update-clie/" + id;
    return this.http.put(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deleteCliente(id): Observable<any> {
    this.urls = this.apiUrl + "api/delete-clie/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getRestaurantes(): Observable<any> {
    this.urls = this.apiUrl + "api/restaurantes";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createRestaurante(nombre, ubicacion, lat, lng, numero_cel,
    carecteristicas, horario_apertura, horario_cierre,
    banner, logo, estado, password, categorias_id): Observable<any> {
    var datoaEnviar = {
      "nombre": nombre,
      "ubicacion": ubicacion,
      "lat": lat,
      "lng": lng,
      "numero_cel": numero_cel,
      "caracteristicas": carecteristicas,
      "horario_apertura": horario_apertura,
      "horario_cierre": horario_cierre,
      "banner": banner,
      "logo": logo,
      "estado": estado,
      "password": password,
      "categorias_id": categorias_id,
    }
    this.urls = this.apiUrl + "api/create-restaurante";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editRestaurante(id, nombre, ubicacion, numero_cel,
    caracteristicas, horario_apertura, horario_cierre,
    banner, logo, estado, password,current_days, lat, lng): Observable<any> {
    var datoaEnviar = {
      "nombre": nombre,
      "ubicacion": ubicacion,
      "lat": lat,
      "lng": lng,
      "numero_cel": numero_cel,
      "caracteristicas": caracteristicas,
      "horario_apertura": horario_apertura,
      "horario_cierre": horario_cierre,
      "banner": banner,
      "logo": logo,
      "estado": estado,
      "password": password,
      "current_days": current_days,
    }
    this.urls = this.apiUrl + "api/update-restaurante/" + id;
    return this.http.put(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deleteRestaurante(id): Observable<any> {
    this.urls = this.apiUrl + "api/delete-restaurante/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getRepartidores(): Observable<any> {
    this.urls = this.apiUrl + "api/repartidores";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createRepartidor(nombres, apellidos, edad, domicilio,
    numero_ci, foto_placa, foto_vehiculo, numero_cel, estado,
    foto,zona): Observable<any> {
    var datoaEnviar = {
      "nombres": nombres,
      "apellidos": apellidos,
      "edad": edad,
      "domicilio": domicilio,
      "foto_placa": foto_placa,
      "numero_ci": numero_ci,
      "foto_vehiculo": foto_vehiculo,
      "numero_cel": numero_cel,
      "verified": estado,
      "foto": foto,
      "zona":zona
    }
    this.urls = this.apiUrl + "api/create-rep";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editRepartidor(id, nombres, apellidos, edad, domicilio,
    numero_ci, foto_placa, foto_vehiculo, numero_cel, estado,
    foto,zona): Observable<any> {
    var datoaEnviar = {
      "nombres": nombres,
      "apellidos": apellidos,
      "edad": edad,
      "domicilio": domicilio,
      "foto_placa": foto_placa,
      "numero_ci": numero_ci,
      "foto_vehiculo": foto_vehiculo,
      "numero_cel": numero_cel,
      "estado": estado,
      "foto": foto,
      "zona":zona
    }
    this.urls = this.apiUrl + "api/update-rep/" + id;
    return this.http.put(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editEstadoRepartidor(id, estado): Observable<any> {
    var datoaEnviar = {
      "estado": estado,
    }
    this.urls = this.apiUrl + "api/act-rep/" + id;
    return this.http.put(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editVerifiedRepartidor(id, verified): Observable<any> {
    var datoaEnviar = {
      "verified": verified,
    }
    this.urls = this.apiUrl + "api/act-ser/" + id;
    return this.http.put(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deleteRepartidor(id): Observable<any> {
    this.urls = this.apiUrl + "api/delete-rep/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getMediosTransporte(repartidores_id): Observable<any> {
    var datoaEnviar = {
      "repartidores_id": repartidores_id
    }
    this.urls = this.apiUrl + "api/mediostransprote";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createMedioTransporte(foto_placa, foto_vehiculo, numero_placa, repartidores_id): Observable<any> {
    var datoaEnviar = {
      "foto_placa": foto_placa,
      "foto_vehiculo": foto_vehiculo,
      "placa": numero_placa,
      "repartidores_id": repartidores_id,
    }
    this.urls = this.apiUrl + "api/create-medtr";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editMedioTransporte(id, foto_placa, foto_vehiculo, numero_placa): Observable<any> {
    var datoaEnviar = {
      "foto_placa": foto_placa,
      "foto_vehiculo": foto_vehiculo,
      "placa": numero_placa,
    }
    this.urls = this.apiUrl + "api/update-medtr/" + id;
    return this.http.put(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deleteMedioTransporte(id): Observable<any> {
    this.urls = this.apiUrl + "api/delete-medtr/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getMenus(): Observable<any> {
    this.urls = this.apiUrl + "api/list-custom";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createMenu(dias, restaurantes_id): Observable<any> {
    var datoaEnviar = {
      "dias": dias,
      "restaurantes_id": restaurantes_id,
    }
    this.urls = this.apiUrl + "api/create-list";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getRestHA(): Observable<any> {
    this.urls = this.apiUrl + "api/list-restaurantes";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getMenusRest(restaurantes_id): Observable<any> {
    var datoaEnviar = {
      "restaurantes_id": restaurantes_id
    }
    this.urls = this.apiUrl + "api/menus";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createMenusRest(nombre, descripcion, restaurantes_id): Observable<any> {
    var datoaEnviar = {
      "nombre": nombre,
      "descripcion": descripcion,
      "restaurantes_id": restaurantes_id,
    }
    this.urls = this.apiUrl + "api/register-menu";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }
  editMenuRest(id, nombre, descripcion,): Observable<any> {
    var datoaEnviar = {
      "nombre": nombre,
      "descripcion": descripcion,
    }
    this.urls = this.apiUrl + "api/update-menu/" + id;
    return this.http.put(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deleteMenuRest(id): Observable<any> {
    this.urls = this.apiUrl + "api/delete-menu/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createPlatoRest(nombre, descripcion, precio, estado, foto, menus_id): Observable<any> {
    var datoaEnviar = {
      "nombre": nombre,
      "descripcion": descripcion,
      "precio": precio,
      "estado": estado,
      "foto": foto,
      "menus_id": menus_id,
    }
    this.urls = this.apiUrl + "api/store-plato";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editPlatoRest(id, nombre, descripcion, precio, estado, foto): Observable<any> {
    var datoaEnviar = {
      "nombre": nombre,
      "descripcion": descripcion,
      "precio": precio,
      "estado": estado,
      "foto": foto,
    }
    this.urls = this.apiUrl + "api/update-plato/" + id;
    return this.http.put(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  // deleteMenuRest(id): Observable<any> {
  //   this.urls = this.apiUrl + "api/delete-plato/" + id;
  //   return this.http.delete(this.urls, this.httpOptions)
  //     .pipe(
  //       tap((data: any) => {
  //         return of(data);
  //       }),
  //       catchError((err) => {

  //         return throwError(err);
  //       })
  //     );
  // }

  getMenusFoto(menus_id): Observable<any> {
    var datoaEnviar = {
      "menus_id": menus_id
    }
    this.urls = this.apiUrl + "api/fotos-menu";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createMenusFoto(foto, menus_id): Observable<any> {
    var datoaEnviar = {
      "foto": foto,
      "menus_id": menus_id
    }
    this.urls = this.apiUrl + "api/register-fotmenu";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editMenusFoto(id, foto): Observable<any> {
    var datoaEnviar = {
      "foto": foto,
    }
    this.urls = this.apiUrl + "api/update-fotmenu/" + id;
    return this.http.put(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deleteMenusFoto(id): Observable<any> {
    this.urls = this.apiUrl + "api/delete-fotmenu/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getPlatos(menu_id): Observable<any> {
    var datoaEnviar = {
      "menus_id": menu_id
    }
    this.urls = this.apiUrl + "api/platos";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createPlato(nombre, precio, descripcion, descuento, menus_id): Observable<any> {
    var datoaEnviar = {
      "nombre": nombre,
      "precio": precio,
      "descripcion": descripcion,
      "descuento": descuento,
      "menus_id": menus_id
    }
    this.urls = this.apiUrl + "api/register-plato";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editPlato(id, nombre, precio, descripcion, descuento): Observable<any> {
    var datoaEnviar = {
      "nombre": nombre,
      "precio": precio,
      "descripcion": descripcion,
      "descuento": descuento
    }
    this.urls = this.apiUrl + "api/update-plato/" + id;
    return this.http.put(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deletePlato(id): Observable<any> {
    this.urls = this.apiUrl + "api/delete-plato/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getGuarniciones(platos_id): Observable<any> {
    var datoaEnviar = {
      "platos_id": platos_id
    }
    this.urls = this.apiUrl + "api/guarniciones";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createGuarnicion(nombre, precio, descripcion, descuento, platos_id): Observable<any> {
    var datoaEnviar = {
      "nombre": nombre,
      "precio": precio,
      "descripcion": descripcion,
      "descuento": descuento,
      "platos_id": platos_id
    }
    this.urls = this.apiUrl + "api/register-gua";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editGuarnicion(id, nombre, precio, descripcion, descuento): Observable<any> {
    var datoaEnviar = {
      "nombre": nombre,
      "precio": precio,
      "descripcion": descripcion,
      "descuento": descuento
    }
    this.urls = this.apiUrl + "api/update-gua/" + id;
    return this.http.put(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deleteGuarnicion(id): Observable<any> {
    this.urls = this.apiUrl + "api/delete-gua/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getPlatoFoto(platos_id): Observable<any> {
    var datoaEnviar = {
      "platos_id": platos_id
    }
    this.urls = this.apiUrl + "api/fotos-plato";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createPlatoFoto(foto, platos_id): Observable<any> {
    var datoaEnviar = {
      "foto": foto,
      "platos_id": platos_id
    }
    this.urls = this.apiUrl + "api/register-fotplato";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editPlatoFoto(id, foto): Observable<any> {
    var datoaEnviar = {
      "foto": foto,
    }
    this.urls = this.apiUrl + "api/update-fotplato/" + id;
    return this.http.put(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deletePlatoFoto(id): Observable<any> {
    this.urls = this.apiUrl + "api/delete-fotplato/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getCupones(): Observable<any> {
    this.urls = this.apiUrl + "api/cupones";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createCupon(nombre, descripcion, descuento, fecha_vencimiento, hora_vencimiento, tipo, monto_maximo, resturantes_id): Observable<any> {
    var datoaEnviar = {
      "nombre": nombre,
      "descripcion": descripcion,
      "descuento": descuento,
      "fecha_vencimiento": fecha_vencimiento,
      "hora_vencimiento": hora_vencimiento,
      "tipo": tipo,
      "monto_maximo": monto_maximo,
      "restaurantes_id": resturantes_id,
    }
    this.urls = this.apiUrl + "api/create-cupon";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editCupon(id, nombre, descripcion, descuento, fecha_vencimiento, hora_vencimiento, estado): Observable<any> {
    var datoaEnviar = {
      "nombre": nombre,
      "descripcion": descripcion,
      "descuento": descuento,
      "fecha_vencimiento": fecha_vencimiento,
      "hora_vencimiento": hora_vencimiento,
      "estado": estado,
    }
    this.urls = this.apiUrl + "api/update-cupon/" + id;
    return this.http.put(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deleteCupon(id): Observable<any> {
    this.urls = this.apiUrl + "api/delete-cupon/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getRestaurantesHa(): Observable<any> {
    this.urls = this.apiUrl + "api/restaurantes-habilitados";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getClientesEs(): Observable<any> {
    this.urls = this.apiUrl + "api/list-esp";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  sendCupones(array, id): Observable<any> {
    this.urls = this.apiUrl + "api/cupon-send/" + id;
    return this.http.post(this.urls, array, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  historialPedidos(clientes_id): Observable<any> {
    var datoaEnviar = {
      "clientes_id": clientes_id,
    }
    this.urls = this.apiUrl + "api/mis-pedidos";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  historialPedidosRepartidor(id): Observable<any> {
    var datoaEnviar = {
      "id": id,
    }
    this.urls = this.apiUrl + "api/pedidos-rep";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  horasTrabajadasTotal(id): Observable<any> {
    var datoaEnviar = {
      "id": id,
    }
    this.urls = this.apiUrl + "api/hrs-rep";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  horasTrabajadasSemana(id): Observable<any> {
    var datoaEnviar = {
      "id": id,
    }
    this.urls = this.apiUrl + "api/hrscomplete-rep";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  horasTrabajadasMes(id): Observable<any> {
    var datoaEnviar = {
      "id": id,
    }
    this.urls = this.apiUrl + "api/hrscomplete2-rep";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  pagosCount(id): Observable<any> {
    var datoaEnviar = {
      "id": id,
    }
    this.urls = this.apiUrl + "api/pagos-rep";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  crearNotifOneRep(id, descripcion): Observable<any> {
    var datoaEnviar = {
      "id": id,
      "descripcion": descripcion,
    }
    this.urls = this.apiUrl + "api/sendone-rep";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  crearNotifMultRep(descripcion): Observable<any> {
    var datoaEnviar = {
      "descripcion": descripcion,
    }
    this.urls = this.apiUrl + "api/sendmult-rep";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  cliente(): Observable<any> {
    this.urls = this.apiUrl + "api/versiones-clie";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createClienteVer(nombre,nombre_ios): Observable<any> {
    var datoaEnviar = {
      "nombre": nombre,
      "nombre_ios": nombre_ios,
    }
    this.urls = this.apiUrl + "api/register-versionclie";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editClienteVer(id, nombre): Observable<any> {
    var datoaEnviar = {
      "nombre": nombre,
    }
    this.urls = this.apiUrl + "api/update-versionclie/" + id;
    return this.http.put(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  repartidor(): Observable<any> {
    this.urls = this.apiUrl + "api/versiones-rep";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createRepVer(nombre,nombre_ios): Observable<any> {
    var datoaEnviar = {
      "nombre": nombre,
      "nombre_ios": nombre_ios,

    }
    this.urls = this.apiUrl + "api/register-versionrep";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editRepVer(id, nombre): Observable<any> {
    var datoaEnviar = {
      "nombre": nombre,
    }
    this.urls = this.apiUrl + "api/update-versionrep/" + id;
    return this.http.put(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  restaurante(): Observable<any> {
    this.urls = this.apiUrl + "api/versiones-res";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createRestVer(nombre,nombre_ios): Observable<any> {
    var datoaEnviar = {
      "nombre": nombre,
      "nombre_ios": nombre_ios,

    }
    this.urls = this.apiUrl + "api/register-versionrest";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editRestVer(id, nombre): Observable<any> {
    var datoaEnviar = {
      "nombre": nombre,
    }
    this.urls = this.apiUrl + "api/update-versionrest/" + id;
    return this.http.put(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createNotifcAllUser(descripcion): Observable<any> {
    var datoaEnviar = {
      "descripcion": descripcion,
    }
    this.urls = this.apiUrl + "api/ntf-mult";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getPedidos(): Observable<any> {
    this.urls = this.apiUrl + "api/pedidos-all";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getPedido(idPedido): Observable<any> {
    this.urls = this.apiUrl + "api/ver-pedido-state/"+idPedido+'';
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  updatePedido(estado,pedidos_id): Observable<any> {
    console.log(this.token);
    
    var datoaEnviar = {
      "estado": estado
    }
    this.urlL ="https://api.namnamapp.com/api/pedidos-estado/"+pedidos_id+"";
    return this.http.post(this.urlL, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        }));

  }

  updateRejected(estado,pedidos_id,detalle_rechazo): Observable<any> {
    console.log(this.token);
    
    var datoaEnviar = {
      "estado": estado,
      "detalle_rechazo":detalle_rechazo
    }
    this.urlL ="https://api.namnamapp.com/api/pedidos-estado-rejected/"+pedidos_id+"";
    return this.http.post(this.urlL, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        }));

  }

  orderReady(pedido_id): Observable<any> {
    

    return this.http.get(this.path + "pedido-listo/"+pedido_id, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        }));

  }

  getPedidoOrderTracking(idPedido): Observable<any> {
    this.urls = this.apiUrl + "api/tracking-order-state/"+idPedido+'';
    return this.http.get(this.urls)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getCosto(restaurantes_id, lat, lng): Observable<any> {
    var datoaEnviar = {
      "restaurantes_id": restaurantes_id,
      "lat": lat,
      "lng": lng
    }
    this.urls = this.apiUrl + "api/costo-envio";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createPedido(total, direccion_entrega, lat, lng, restaurantes_id, clientes_id, costoenvio, detalle): Observable<any> {
    var datoaEnviar = {
      "total": total,
      "direccion_entrega": direccion_entrega,
      "lat": lat,
      "lng": lng,
      "restaurantes_id": restaurantes_id,
      "clientes_id": clientes_id,
      "costoenvio": costoenvio,
      "detalle": detalle,
    }
    this.urls = this.apiUrl + "api/create-pedidos-adm";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getRepAct(): Observable<any> {
    this.urls = this.apiUrl + "api/rep-act";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  asignarRep(repartidor, pedido): Observable<any> {
    var datoaEnviar = {
      "repartidor": repartidor,
      "pedido": pedido,
    }
    this.urls = this.apiUrl + "api/update-pedido-deli";
    return this.http.post(this.urls, datoaEnviar, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }
}