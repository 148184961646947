
<div style="height: 100%; border: 1px solid; overflow: auto;">
    <div align="left" *ngIf="lng">
        <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false">
    
            <agm-marker [iconUrl]="iconRes" [latitude]="latRes" [longitude]="lngRes">
    
                <agm-info-window>
                    <strong style="color:black">Ubicación Restaurante</strong>
                </agm-info-window>
    
            </agm-marker>
            <agm-marker [iconUrl]="icon" [latitude]="latC" [longitude]="lngC">
    
                <agm-info-window>
                    <strong style="color:black">Ubicación Cliente</strong>
                </agm-info-window>
    
            </agm-marker>
        </agm-map>
    
    </div>
    <div >
        <mat-tab-group>
            <mat-tab class="ss" label="Cliente">
                <section class="mm">
                    <div class="example-label">Referencias</div>
                    <div class="example-button-row">
                        <a target="_blank"
                            href="https://api.whatsapp.com/send?phone=591{{clienteCelular}}&text={{bodyMessageCliente}}">
                          <button mat-raised-button color="warn" >Contactar cliente</button>&nbsp;
                             <br>
                             <br>
                             <a target="_blank" href="https://maps.google.com?q={{latC}},{{lngC}}">
                                <button mat-raised-button color="warn" >Ir a Ubicación</button>&nbsp;

                             </a>
                        </a>
    
    
                    </div>
                </section>
                <div class="detail-cliente">
                    <h5><b> Nombre del Cliente:</b>&nbsp;{{clienteName}} {{clienteApellido}}
                    </h5>
                    <h5><b> Dirección:</b>&nbsp;{{clienteDireccion}} </h5>
    
                </div>
            </mat-tab>
            <mat-tab label="Restaurante">
                <section class="mm">
                    
                    <div class="example-label">Referencias</div>
                    <div class="example-button-row">
                        <a target="_blank"
                            href="https://api.whatsapp.com/send?phone=591{{resCelular}}&text={{bodyMessageRes}}">
                            <button mat-raised-button color="primary">
    
                                Contactar Negocio</button>
                        </a>
                        <div class="detail-res">
    
                            <h5><b> Nombre del Negocio:</b>&nbsp;{{resName}}</h5>
                            <h5><b> Dirección:</b>&nbsp;{{resDireccion}} </h5>
                        </div>
                    </div>
                </section>
            </mat-tab>
            <mat-tab label="Detalle del Pedido">
    
                <div class="detail-res-t">
                    <table mat-table [dataSource]="dataSource" style="width:100%;     box-shadow: none;"
                        class="mat-elevation-z8">
    
    
                        <ng-container matColumnDef="position">
                            <th mat-header-cell *matHeaderCellDef> Nombre Producto </th>
                            <td mat-cell *matCellDef="let element"> {{element.plato}} </td>
                        </ng-container>
                        <ng-container matColumnDef="precio">
                            <th mat-header-cell *matHeaderCellDef> Precio </th>
                            <td mat-cell *matCellDef="let element"> {{element.total}} </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Cantidad </th>
                            <td mat-cell *matCellDef="let element"> {{element.cantidad}} </td>
                        </ng-container>
    
    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <br>
                    <table align="center"> 
                        <tr align="center">
                            <th >Precio Delivery</th>
                            <th >Total Pedido</th>
                            <th >Total</th>
    
                        </tr>
                        <tr align="center">
                            <td>Total: {{delivery}} Bs</td>
                            <td>Total: {{total_pedido}} Bs</td>
                            <td>Delivery + Pedido: {{general_total}} Bs</td>
    
                        </tr>
    
                    </table>
                    <div align="right">
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
        <br>
    
    </div>
    
</div>

