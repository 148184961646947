import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { PedidoDetailComponent } from './pedido-detail/pedido-detail.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
  },
  { path: 'trackingOrder-detail/:id', component: PedidoDetailComponent},
  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'dashboards/analytics',
        redirectTo: '/'
      },
      {
        path: '',
        loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
        canActivate: [AuthGuard],
        data: { roles: ['All'] }
      },
      {
        path: 'apps',
        children: [
          {
            path: 'users',
            loadChildren: () => import('./pages/apps/users/users.module').then(m => m.UsersModule),
            canActivate: [AuthGuard],
            data: { roles: ['Administrador'] }
          },
          {
            path: 'clientes',
            loadChildren: () => import('./pages/apps/clientes/clientes.module').then(m => m.ClientesModule),
            canActivate: [AuthGuard],
            data: { roles: ['Administrador'] }
          },
          {
            path: 'repartidores',
            loadChildren: () => import('./pages/apps/repartidores/repartidores.module').then(m => m.RepartidoresModule),
            canActivate: [AuthGuard],
            data: { roles: ['All'] }
          },
          {
            path: 'costos',
            loadChildren: () => import('./pages/apps/costos/costos.module').then(m => m.CostosModule),
            canActivate: [AuthGuard],
            data: { roles: ['Administrador'] }
          },
          {
            path: 'reportes',
            loadChildren: () => import('./pages/apps/reportes/reportes.module').then(m => m.ReportesModule),
            canActivate: [AuthGuard],
            data: { roles: ['Administrador'] }
          },
          {
            path: 'pedidos',
            loadChildren: () => import('./pages/apps/pedidos/pedidos.module').then(m => m.PedidosModule),
            canActivate: [AuthGuard],
            data: { roles: ['All'] }
          },
          {
            path: 'restaurantes',
            loadChildren: () => import('./pages/apps/restaurantes/restaurantes.module').then(m => m.RestaurantesModule),
            canActivate: [AuthGuard],
            data: { roles: ['All'] }
          },
          {
            path: 'categorias',
            loadChildren: () => import('./pages/apps/categorias/categorias.module').then(m => m.CategoriasModule),
            canActivate: [AuthGuard],
            data: { roles: ['Administrador'] }
          },
        ]
      },
    ]
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
